<template>
	<div>
		<CHeader fixed with-subheader light>
			<CToggler in-header class="ml-3 d-lg-none" @click="$store.commit('toggleSidebarMobile')" />
			<CToggler
				in-header
				class="ml-3 d-md-down-none"
				@click="$store.commit('toggleSidebarDesktop')"
			/>
			<CHeaderBrand class="mx-auto d-lg-none" to="/">
				<CIcon name="logo" height="48" alt="Logo" />
			</CHeaderBrand>
			<CHeaderNav class="d-md-down-none mr-auto" />
			<CHeaderNav class="mr-4">
				<div class="mr-3 position-relative cursor-pointer">
					<CDropdown
						v-if="!isQA"
						:show.sync="showNotice"
						:caret="false"
						add-toggler-classes="p-0 btn__dropdown"
						add-menu-classes="p-0 notice__list"
						placement="bottom-end"
						:offset="[5, 5]"
					>
						<template #toggler-content>
							<div
								v-if="countUnread > 0"
								:style="countUnread > 99 ? { 'font-size': '9px' } : null"
								class="notice__count"
								>{{ countUnread > 99 ? "99+" : countUnread }}</div
							>
							<CIcon name="cilBell" class="icon__bell" />
						</template>
						<CDropdownHeader class="py-3">
							<div class="font-lg d-flex justify-content-between align-items-center">
								<span>Notifications</span>
								<router-link to="/notifications">View all</router-link>
							</div>
						</CDropdownHeader>
						<template v-for="(notification, index) in notifications">
							<CDropdownItem
								:key="notification.id"
								class="notice__item font-lg"
								:class="{
									'border-bottom': index !== notifications.length - 1,
									read: notification.isRead,
									unread: !notification.isRead,
								}"
								@click="redirectNoti(notification)"
							>
								<div class="d-flex justify-content-between align-items-center w-100">
									<div class="notice__content">
										<div class="notice__body" v-html="notification.body"></div>
										<div class="notice__date">{{ convertUTCtoDateVN(notification.updatedAt) }}</div>
									</div>
									<font-awesome-icon
										v-if="notification.isRead"
										:icon="['far', 'bookmark']"
										@click.stop="unreadNoti(notification)"
									/>
								</div>
							</CDropdownItem>
						</template>
						<InfiniteLoading
							v-if="isAppendLoading(notifications)"
							:identifier="notiId"
							class="loading"
							@infinite="getNoti"
						>
							<div slot="no-more"></div>
							<div slot="no-results"></div>
						</InfiniteLoading>
					</CDropdown>
				</div>
				<div class="mr-3 font-weight-bold">{{ getName }}</div>
				<TheHeaderDropdownAccnt />
			</CHeaderNav>
			<CSubheader class="px-3">
				<CBreadcrumbRouter class="border-0 mb-0" />
			</CSubheader>
		</CHeader>
	</div>
</template>

<script>
import { startCase } from "lodash-es"
import { createNamespacedHelpers, mapActions, mapMutations, mapState } from "vuex"
import { USER_ROLE } from "../plugins/constants"
const { mapState: authState } = createNamespacedHelpers("authentication")
export default {
	name: "TheHeader",
	components: {
		TheHeaderDropdownAccnt: () => import("./TheHeaderDropdownAccnt"),
		InfiniteLoading: () => import("vue-infinite-loading"),
		Notify: () => import("../components/Notify.vue"),
	},
	data() {
		return {
			showNotice: false,
			limit: 20,
			offset: 0,
			notiId: Number(new Date()),
		}
	},
	computed: {
		...authState(["currentUser"]),
		...mapState(["notifications", "countUnread"]),
		getName() {
			if (this.currentUser) {
				return startCase(this.currentUser.name)
			}
			return null
		},
		isQA() {
			if (this.currentUser.role === USER_ROLE.QA) return true
			return false
		},
	},
	watch: {
		async showNotice(val) {
			this.set(["notifications", []])
			if (!val) {
				this.notiId += 1
				this.offset = 0
			} else {
				await this.getNoti()
			}
		},
	},
	created() {
		this.getCountUnread()
	},
	mounted() {
		if ("serviceWorker" in navigator) {
			navigator.serviceWorker.addEventListener("message", this.handleMessage)
		}
	},
	beforeDestroy() {
		if ("serviceWorker" in navigator) {
			navigator.serviceWorker.removeEventListener("message", this.handleMessage)
		}
		this.set(["notifications", []])
	},
	methods: {
		...mapActions([
			"getNotifications",
			"getCountUnread",
			"readNotiSync",
			"unreadNotify",
			"redirectNoti",
		]),
		...mapMutations(["set"]),
		handleMessage() {
			this.getCountUnread()
		},
		async getNoti($state) {
			this.offset = this.notifications.length
			const params = {
				limit: this.limit,
				offset: this.offset,
			}
			const res = await this.getNotifications(params)
			if (!$state) return
			if (res.length) {
				$state.loaded()
			} else {
				$state.complete()
			}
		},
		async unreadNoti(noti) {
			await this.unreadNotify({ id: noti.id })
		},
		isAppendLoading(array) {
			return array.length >= this.limit
		},
	},
}
</script>
<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";
.notice__count {
	width: 20px;
	height: 20px;
	border-radius: 50%;
	background: $base-color;
	position: absolute;
	top: -2px;
	right: -4px;
	color: #fff;

	font-size: 11px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.cursor-pointer {
	cursor: pointer;
}

.icon__bell {
	width: 24px !important;
	height: 24px !important;
}

.notice {
	&__content {
		width: calc(100% - 10px);
		margin-right: 24px;
	}

	&__item {
		background-color: $white;

		&:active,
		&.read {
			background-color: #ebedef80;
		}

		&:hover {
			background: unset;
			color: unset !important;
			box-shadow: inset 1px 0 0 #dadce0, inset -1px 0 0 #dadce0, 0 1px 2px 0 #3c40434d,
				0 1px 3px 1px #3c404326;
			z-index: 2;
		}
	}

	&__body {
		white-space: break-spaces;

		font-weight: bolder;

		&.unread {
			font-weight: normal;
		}
	}
}
</style>

<style lang="scss">
.btn__dropdown {
	&:focus {
		box-shadow: unset !important;
	}
}

.notice__list {
	max-width: 600px;
	min-width: 500px;
	max-height: 400px;
	overflow-y: auto;
}

b {
	font-weight: normal;
}

.unread b {
	font-weight: bolder;
}
</style>
